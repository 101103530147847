import _, { set } from "lodash";
import React, { useEffect, useState } from "react";
import { SemanticWIDTHS, Table } from "semantic-ui-react";
import { IWPListTable, TableColumn } from "../Types/IWPList";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";

function exampleReducer(state: any, action: any): any {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: "ascending",
      };
    case "CHANGE_DATA":
      return {
        ...state,
        data: action.data,
      };
    default:
      throw new Error();
  }
}

const CustomTable: React.FC<IWPListTable> = ({
  columns,
  keyColumn,
  tableData,
  triggerChange,
  deleteColumnHandler,
  editColumnHandler,
  downloadColumnHandler,
  selectColumnHandler,
}) => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [selected, setSelected] = useState<any[]>([]);
  const inpColumns = columns.filter((column) => column.type?.includes("input"));

  const handleIWPDelete = (id: string) => {
    MySwal.fire({
      title: `Delete this IWP?`,
      text: `${id} will be deleted permanently.`,
      icon: "warning",
      color: "#f1f1f1",
      background: "#444444",
      showCancelButton: true,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteColumnHandler && deleteColumnHandler(id);
        MySwal.fire({
          title: "Deleted!",
          text: "the IWP " + id + " has been deleted.",
          icon: "success",
          color: "#f1f1f1",
          background: "#444444",
        });
        deleteColumnHandler && deleteColumnHandler(id);
      }
    });
  };

  useEffect(() => {
    const inpColumnNames = inpColumns.reduce((acc, column) => {
      return { ...acc, name: column.name, value: "" };
    }, {});

    // find values of all the input columns in the tableData
    const myData = tableData as any;
    const initialInpValues = myData.reduce((acc: any, row: any) => {
      return {
        ...acc,
        [row[keyColumn as string]]: inpColumns.reduce((acc, column) => {
          return { ...acc, [column.name]: row[column.name] };
        }, {}),
      };
    }, {});
    setInpVals(initialInpValues);
  }, [tableData]);

  const [inpVals, setInpVals] = React.useState<any>();
  const [state, dispatch] = React.useReducer(exampleReducer, {
    column: null,
    data: tableData,
    direction: null,
  });
  const { column, data, direction } = state;

  // check each row of tableData and data if they are same or not

  if (tableData.length !== data.length) {
    dispatch({ type: "CHANGE_DATA", data: tableData });
  }

  // // console.log(tableData, data);

  const getIWPInfo = (iwp: any) => {
    return navigate(`${iwp}`);
  };

  // console.log(inpVals);

  return (
    <Table
      sortable
      celled
      inverted
      selectable
      unstackable
      verticalAlign="middle"
    >
      <Table.Header className="tableHeader">
        <Table.Row>
          {columns.map((tcolumn: TableColumn) => {
            if (!tcolumn.options.hide) {
              if (tcolumn.name === "Select") {
                return (
                  <Table.HeaderCell
                    key={tcolumn.name}
                    {...(tcolumn.options.width && {
                      width: tcolumn.options.width as SemanticWIDTHS,
                    })}
                    sorted={column === tcolumn.name ? direction : null}
                    onClick={() => {
                      selected.length > 0
                        ? setSelected([])
                        : setSelected(tableData.map((item) => item.IWP));

                      selectColumnHandler &&
                        selectColumnHandler(
                          selected.length > 0
                            ? []
                            : [...tableData.map((item) => item.IWP)]
                        );
                    }}
                  >
                    {tcolumn.label}
                  </Table.HeaderCell>
                );
              }
              return (
                <Table.HeaderCell
                  key={tcolumn.name}
                  {...(tcolumn.options.width && {
                    width: tcolumn.options.width as SemanticWIDTHS,
                  })}
                  sorted={column === tcolumn.name ? direction : null}
                  onClick={() =>
                    tcolumn.options.sort &&
                    dispatch({ type: "CHANGE_SORT", column: tcolumn.name })
                  }
                >
                  {tcolumn.label}
                </Table.HeaderCell>
              );
            }
            return null;
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body className="tableBody">
        {data.map((val: any) => (
          //stop the action of clicking on the row if the column has delete button

          <Table.Row
            className="table-row"
            key={`${val[keyColumn as string]}-row`}
          >
            {columns.map((tcolumn: TableColumn, index) => {
              if (tcolumn.type?.includes("input")) {
                return (
                  <Table.Cell
                    key={`${val[keyColumn as string]}-column-${tcolumn.name}`}
                    onClick={() => {
                      val.Status && getIWPInfo(val.IWP);
                    }}
                  >
                    {inpVals &&
                      Object.keys(inpVals).length === tableData.length && (
                        <input
                          type={tcolumn.type.split("-")[1]}
                          min={0}
                          max={100}
                          step={5}
                          className="form-control"
                          value={
                            inpVals[val[keyColumn as string]][tcolumn.name]
                          }
                          style={{ maxWidth: "75px" }}
                          onChange={(e) => {
                            const eValue =
                              e.target.value === "" ? "0" : e.target.value;
                            if (parseInt(eValue) <= 100) {
                              setInpVals({
                                ...inpVals,
                                [val[keyColumn as string]]: {
                                  ...inpVals[val[keyColumn as string]],
                                  [tcolumn.name]: e.target.value,
                                },
                              });
                              triggerChange &&
                                triggerChange(
                                  val[keyColumn as string],
                                  tcolumn.name,
                                  e.target.value
                                );
                            }
                          }}
                        />
                      )}
                  </Table.Cell>
                );
              }

              if (!tcolumn.options.hide) {
                if (tcolumn.name === "delete") {
                  return (
                    <Table.Cell
                      key={`${tcolumn.name}-${val[keyColumn as string]}`}
                    >
                      <p
                        className="btn btn-danger"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteColumnHandler &&
                            deleteColumnHandler(val[keyColumn as string]);
                        }}
                      >
                        Delete
                      </p>
                    </Table.Cell>
                  );
                } else if (tcolumn.name === "edit") {
                  return (
                    <Table.Cell
                      key={`${tcolumn.name}-${val[keyColumn as string]}`}
                    >
                      <p
                        className="btn btn-info"
                        onClick={(e) => {
                          e.preventDefault();
                          editColumnHandler && editColumnHandler(val);
                        }}
                      >
                        Edit
                      </p>
                    </Table.Cell>
                  );
                } else if (tcolumn.name === "Delete") {
                  return (
                    <Table.Cell
                      key={`${tcolumn.name}-${val[keyColumn as string]}`}
                    >
                      <p
                        className="btn btn-danger p-1"
                        onClick={(e) => {
                          e.preventDefault();
                          handleIWPDelete(val.IWP);
                        }}
                      >
                        Delete
                      </p>
                    </Table.Cell>
                  );
                } else if (tcolumn.name === "Download") {
                  return (
                    <Table.Cell
                      key={`${tcolumn.name}-${val[keyColumn as string]}`}
                    >
                      <p
                        className="btn btn-info p-1"
                        onClick={(e) => {
                          e.preventDefault();
                          downloadColumnHandler &&
                            downloadColumnHandler(val.IWP);
                        }}
                      >
                        Download
                      </p>
                    </Table.Cell>
                  );
                } else if (tcolumn.name === "Select") {
                  return (
                    <Table.Cell
                      className="text-center"
                      key={`${tcolumn.name}-${val[keyColumn as string]}`}
                      onClick={() => {
                        if (selected.includes(val.IWP)) {
                          setSelected(
                            selected.filter((item) => item !== val.IWP)
                          );
                          selectColumnHandler &&
                            selectColumnHandler(
                              selected.filter((item) => item !== val.IWP)
                            );
                        } else {
                          setSelected([...selected, val.IWP]);
                          selectColumnHandler &&
                            selectColumnHandler([...selected, val.IWP]);
                        }
                      }}
                    >
                      <label className="custom-checkbox">
                        <input
                          type="checkbox"
                          checked={
                            selected.includes(val.IWP) ||
                            selected.includes("all")
                          }
                          onChange={(e) => {
                            // if (e.target.checked) {
                            //   setSelected([...selected, val.IWP]);
                            // } else {
                            //   setSelected(
                            //     selected.filter((item) => item !== val.IWP)
                            //   );
                            // }
                            // selectColumnHandler &&
                            //   selectColumnHandler(selected);
                          }}
                        />
                      </label>
                    </Table.Cell>
                  );
                } else {
                  return (
                    <Table.Cell
                      key={`${val[keyColumn as string]}-column-${tcolumn.name}`}
                      onClick={() => {
                        val.Status && getIWPInfo(val.IWP);
                      }}
                    >
                      {moment(
                        val[tcolumn.name],
                        "YYYY-MM-DDTHH:mm:ss.SSSZ",
                        true
                      ).isValid()
                        ? moment(val[tcolumn.name].split("T")[0]).format(
                            "YYYY-MM-DD"
                          )
                        : val[tcolumn.name]}
                    </Table.Cell>
                  );
                }
              }
              return null;
            })}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default CustomTable;
