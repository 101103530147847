import Gantt, {
  Tasks,
  Validation,
  Editing,
  Column,
} from "devextreme-react/gantt";
import { Slider } from "devextreme-react";
import { tasks } from "./data";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Application } from "@caxperts/universal.api/Application";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CombineModes } from "@caxperts/universal.api";
import { set } from "lodash";
import { file } from "@babel/types";
import { getUserByIP } from "../util/getIWPData";

function TimelinePlayback() {
  const ganttRef = useRef(null);
  const [currentDate, setCurrentDate] = useState(0);
  const [groupedData, setGroupedData] = useState(null as any);
  const [ganttInitialized, setGanttInitialized] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playInterval, setPlayInterval] = useState(null as any);
  const [IWPList, setIWPList] = useState(null as any);
  const [range, setRange] = useState(null as any);

  //   const scrollBar = document.getElementsByClassName("dx-scrollable-scroll")[2];
  //   const scrollableContainer = document.getElementById("dx-gantt-ta");
  //   var SCROLL_CONST = 0;
  //   if (scrollableContainer && scrollBar) {
  //     SCROLL_CONST = scrollableContainer.clientWidth - 1217;
  //   }
  //   const scrollHeader = document.getElementsByClassName("dx-gantt-tsa");
  //   const scrollMain = document.getElementsByClassName(
  //     "dx-scrollable-content"
  //   )[1];

  // function capturescroll() {
  //   var scrollProgress =
  //     document
  //       .getElementsByClassName("dx-scrollable-scroll")[2]
  //       .getAttribute("style") || "";
  //   //keep text from "translate(" to the next ","
  //   var scrollProgressValue = scrollProgress.substring(
  //     scrollProgress.indexOf("(") + 1,
  //     scrollProgress.indexOf(",") - 2
  //   );
  //   setScrollValue(scrollProgressValue as any);
  //   console.log("scrolling in");
  // }

  function handleValueChange(e: any) {
    const newValue = e.value;
    let date = getActualDateString(newValue);
    console.log(newValue, date);
    compareWithCurrentDate(date, groupedData);
    setCurrentDate(newValue);
  }

  // function compareWithCurrentDate(dateToCompare: any, groupedData: any) {
  //   const currentDate = new Date();
  //   currentDate.setHours(0, 0, 0, 0);
  //   const comparisonDate = new Date(dateToCompare);
  //   comparisonDate.setHours(0, 0, 0, 0);

  //   if (currentDate > comparisonDate) {
  //     console.log("here");
  //   } else if (currentDate <= comparisonDate) {
  //     console.log("there");
  //   }
  // }

  async function compareWithCurrentDate(dateToCompare: any, groupedData: any) {
    let scene = (await Application.getInstance().Scenes3d.get())[0];
    let filter = scene.getNewFilter();
    filter.IncludeAttributes = true;
    filter.CombineMode = CombineModes.Or;
    filter.clearSelection();
    const comparisonDate = new Date(dateToCompare);
    comparisonDate.setHours(0, 0, 0, 0);

    Object.values(groupedData).forEach((group: any) => {
      const plannedStart = new Date(group.PlannedStart);
      const plannedEnd = new Date(group.PlannedFinish);
      plannedStart.setHours(0, 0, 0, 0);
      plannedEnd.setHours(0, 0, 0, 0);
      if (comparisonDate < plannedStart) {
        filter.Condition = group.components;
        filter.color("#949494");
      } else if (comparisonDate <= plannedEnd) {
        filter.Condition = group.components;
        filter.color("#FF2400");
      } else if (comparisonDate > plannedEnd) {
        filter.Condition = group.components;
        filter.color("#008000");
      }
    });
  }

  function handlePlay() {
    if (
      currentDate === calculateDaysBetweenDates(range?.minDate, range?.maxDate)
    )
      setCurrentDate(0);
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      console.log("playing");
      setPlayInterval(
        setInterval(() => {
          setCurrentDate((currentDate) =>
            currentDate <=
            calculateDaysBetweenDates(range?.minDate, range?.maxDate)
              ? currentDate + 1
              : 0
          );
        }, 800)
      );
    } else {
      console.log("pausing");
      clearInterval(playInterval);
    }
  }

  async function handleTaskClick(e: any) {
    console.log(e.data.title);
    let ids = [e.data.title];
    let scene = (await Application.getInstance().Scenes3d.get())[0];
    let filter = scene.getNewFilter();
    filter.IncludeAttributes = true;
    filter.CombineMode = CombineModes.Or;
    let datas: any[] = [];

    const promises = ids.map(async (id: any) => {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get3DModelComponents/${id}`
      );
      datas.push(res.data);
    });
    Promise.all(promises).then(async () => {
      let uids = "";
      uids = datas
        .map((data: any) =>
          data.result.map((item: any) => item.UID).join("&uid=")
        )
        .join("&uid=");
      console.log(uids);
      if (uids !== "") {
        filter.Condition = "uid=" + uids;
        // filter.highlight();
        filter.select();
        // // filter.fit();
        // filter.color("Ff0000");
      } else {
        toast.info("IWP does not have any components assigned", {
          theme: "colored",
        });
        filter.clearSelection();
        filter.clearHighlight();
      }
    });
    // } else {
    //   filter.clearSelection();
    //   filter.clearHighlight();
    // }
    // Event handling commands go here
  }

  function convertIWPsToTasks(IWPList: any) {
    var tasks: any = [];
    IWPList.map((iwp: any, index: any) => {
      tasks.push({
        id: index + 1,
        title: iwp.IWP,
        start: new Date(iwp.PlannedStart),
        end: new Date(iwp.PlannedFinish),
        progress: 0,
      });
    });
    return tasks;
  }

  function getActualDate(currentDate: number) {
    var date = new Date(range?.minDate);
    date.setDate(date.getDate() + currentDate);
    return date;
  }

  function getActualDateString(currentDate: number) {
    var date = new Date(range?.minDate);
    date.setDate(date.getDate() + currentDate);
    return moment(date).format("DD MMM YYYY");
  }

    const [user, setUser] = useState(0);

  useEffect(() => {
    const fetchUser = async() => {
      const userID = await getUserByIP()
      setUser(userID)
    }
    fetchUser()
  }, [])



  const { data, isLoading, error }: { data: any; isLoading: any; error: any } =
    useQuery("iwps", () =>
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/iwps/${user}`)
    );


  useEffect(() => {
    if (data) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/get3DModelComponentsWithIWP/${user}`)
        .then((res) => {
          // const groupedData = res.data.result.reduce((acc: any, item: any) => {
          //   const key = `${item.IWP}`;
          //   if (!acc[key]) {
          //     acc[key] = {
          //       IWP: item.IWP,
          //       PlannedStart: item.PlannedStart,
          //       PlannedFinish: item.PlannedFinish,
          //       components: [],
          //     };
          //   }
          //   acc[key].components.push({
          //     UID: item.UID,
          //     name: item.name,
          //     progress: item.progress,
          //     type: item.type,
          //   });
          //   return acc;
          // }, {});
          // setGroupedData(groupedData);
          const groupedData = res.data.result.reduce((acc: any, item: any) => {
            const key = `${item.IWP}`;
            if (!acc[key]) {
              acc[key] = {
                IWP: item.IWP,
                PlannedStart: item.PlannedStart,
                PlannedFinish: item.PlannedFinish,
                components: "",
              };
            }
            acc[key].components += `uid=${item.UID}&`;
            return acc;
          }, {});

          // Remove the trailing '&' from each components string
          Object.values(groupedData).forEach((group: any) => {
            group.components = group.components.slice(0, -1);
          });
          setGroupedData(groupedData);
        })
        .catch((err) => {
          console.error(err);
        });
      console.log(data.data.result);
      setIWPList(data.data.result);
      //find the max and min date and set the range from data.data.result
      const minDate = new Date(
        Math.min.apply(
          null,
          data.data.result.map((iwp: any) => {
            if (iwp.PlannedStart !== null) return new Date(iwp.PlannedStart);
            else return new Date(2029, 0, 1);
          })
        )
      );
      const maxDate = new Date(
        Math.max.apply(
          null,
          data.data.result.map((iwp: any) => {
            return new Date(iwp.PlannedFinish);
          })
        )
      );
      console.log(minDate, maxDate);
      //set the range state to min -7 days and max +7 days
      minDate.setDate(minDate.getDate() - 7);
      maxDate.setDate(maxDate.getDate() + 7);
      setRange({ minDate, maxDate });
    }
  }, [data]);

  useEffect(() => {
    if (ganttInitialized) {
      var ganttref = ganttRef.current || (null as any);
      var gantt = ganttref.instance || null;
      if (!gantt._ganttView) return;
      gantt.scrollToDate(getActualDate(currentDate));
    }
  }, [currentDate, ganttInitialized]);

  useEffect(() => {
    const fetchData = async () => {
      let scene = (await Application.getInstance().Scenes3d.get())[0];
      let filter = scene.getNewFilter();
      filter.IncludeAttributes = true;
      filter.CombineMode = CombineModes.Or;
      filter.Condition = "uid=*";
      filter.color("#949494");
    };
    fetchData();
  }, []);

  return (
    <>
      <ToastContainer autoClose={1500} />
      {IWPList && (
        <>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center cutBBt2 mt-1">
              {isPlaying ? (
                <FontAwesomeIcon
                  icon={icon({
                    name: "circle-pause",
                    family: "classic",
                    style: "solid",
                  })}
                  className="ms-4 me-2 text-danger fa-xl"
                  onClick={() => handlePlay()}
                />
              ) : (
                <FontAwesomeIcon
                  icon={icon({
                    name: "circle-play",
                    family: "classic",
                    style: "solid",
                  })}
                  className="ms-4 me-2 text-primary fa-xl"
                  onClick={() => handlePlay()}
                />
              )}

              <div className="text-white ps-3">
                Date: {getActualDateString(currentDate)}
              </div>
            </div>
            <div className="cutBBt1">
              <Slider
                min={0}
                max={calculateDaysBetweenDates(range?.minDate, range?.maxDate)}
                showRange={false}
                value={currentDate}
                onValueChanged={handleValueChange}
                disabled={isPlaying}
              />
            </div>
          </div>

          <Gantt
            startDateRange={range?.minDate}
            endDateRange={range?.maxDate}
            taskListWidth={300}
            scaleType="weeks"
            height={670}
            onInitialized={() => setGanttInitialized(true)}
            onTaskClick={(e: any) => handleTaskClick(e)}
            ref={ganttRef}
          >
            <Tasks dataSource={convertIWPsToTasks(IWPList)} />

            <Column dataField="title" caption="IWP" width={300} />
          </Gantt>
        </>
      )}
    </>
  );
}

function calculateDaysBetweenDates(startDate: Date, endDate: Date) {
  var diffInMilliSeconds = Math.abs(endDate.getTime() - startDate.getTime());
  return diffInMilliSeconds / (1000 * 60 * 60 * 24);
}

export default TimelinePlayback;
