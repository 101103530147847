import React, { useState } from "react";
import Image from "../../../public/upload.svg";
import axios from "axios";
import FilePreview from "./FilePreview";
import Alert from "react-bootstrap/Alert";
import { Dna } from "react-loader-spinner";
// import Image from "next/image";
// import styles from "../../styles/dropZone.module.css";

const uploadFile = async (files: any, type: any, iwp: any) => {
  const fileUploads: any[] = [];
  let successfulUploads = 0;
  const uploadLength = files.length;

  for (let i = 0; i < uploadLength; i++) {
    const file = files[i];
    const fileNameText = file.name;

    const formData = new FormData();
    formData.append("type", type);
    formData.append("IWP", iwp);
    formData.append("file", file);

    try {
      if (fileNameText.includes(".pdf")) {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/uploadPDFDocuments`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //   // console.log(response);
        successfulUploads++;
        response.data.endResult.forEach((item: any, index: any) => {
          item.documentType = index === 0 ? type : type + "/PNG";
          fileUploads.push(item);
        });

        if (successfulUploads === uploadLength) {
          return fileUploads;
        }
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/uploadCoverPage`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        successfulUploads++;
        response.data.result[0].documentType = type;
        fileUploads.push(response.data.result[0]);
        if (successfulUploads === uploadLength) {
          return fileUploads;
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error(error);
    }
  }
};

const DropZone = ({ data, dispatch, dispatch2, iwp, val }: any) => {
  // onDragEnter sets inDropZone to true
  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };

  // onDragLeave sets inDropZone to false
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
  };

  // onDragOver sets inDropZone to true
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // set dropEffect to copy i.e copy of the source item
    e.dataTransfer.dropEffect = "copy";
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };

  // onDrop sets inDropZone to false and adds files to fileList
  const handleDrop = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const id = e.target.id.split("-")[1];
    // get files from event on the dataTransfer object as an array
    let files = [...e.dataTransfer.files];

    // ensure a file or files are dropped
    if (files && files.length > 0) {
      // dispatch({ type: "FILES_UPLOADED", uploaded: false });
      // loop over existing files
      const existingFiles = data.fileList.map((f: any) => f.name);
      // check if file already exists, if so, don't add to fileList
      // this is to prevent duplicates
      files = files.filter((f) => !existingFiles.includes(f.name));

      const uplodedFiles = await uploadFile(files, id, iwp);

      if (uplodedFiles && uplodedFiles.length > 0) {
        // dispatch action to add droped file or files to fileList
        dispatch({
          type: "ADD_FILE_TO_LIST",
          id,
          files: uplodedFiles,
        });
        // reset inDropZone to false
        dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
      }
    }
  };

  // handle file selection via input element
  const handleFileSelect = async (e: any) => {
    // get files from event on the input element as an array
    let files = [...e.target.files];

    const id = e.target.id.split("-")[1];

    if (files && files.length > 0) {
      dispatch2({
        type: "FILES_NOT_UPLOADED",
        uploaded: false,
        imageType: id,
      });
      // dispatch({ type: "FILES_UPLOADED", uploaded: false });
      // loop over existing files
      const existingFiles = data.fileList.map((f: any) => f.name);
      // check if file already exists, if so, don't add to fileList
      // this is to prevent duplicates
      files = files.filter((f) => !existingFiles.includes(f.name));

      const uplodedFiles = await uploadFile(files, id, iwp);

      if (uplodedFiles && uplodedFiles.length > 0) {
        // console.log(uplodedFiles);

        dispatch2({
          type: "FILES_UPLOADED",
          uploaded: true,
        });
        // dispatch action to add selected file or files to fileList
        dispatch({
          type: "ADD_FILE_TO_LIST",
          id,
          files: uplodedFiles,
        });
      }
    }
  };

  return (
    <>
      <div
        className="dropzone"
        id={`fileSelect_drop-${val}`}
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
      >
        <img src={Image} alt="upload" height={50} width={50} />

        <input
          id={`fileSelect-${val}`}
          type="file"
          multiple
          className="files"
          onChange={(e) => handleFileSelect(e)}
        />
        <label htmlFor={`fileSelect-${val}`}>select multiple Files</label>
        {/* 
        <h3 className="uploadMessage">or drag &amp; drop your files here</h3> */}
      </div>
      {/* Pass the selectect or dropped files as props */}
      {/* <FilePreview data={data} dispatch={dispatch} /> */}
    </>
  );
};

export default DropZone;
