/* eslint-disable no-useless-concat */
import axios from "axios";

export async function getCWP() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getcwp`
    );
    return response.data.result;
  } catch (error) {
    console.error(error);
  }
}

export async function getUserIP(){
  try{
    return await fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      return data.ip
    })
    .catch(error => {
      console.error('Error fetching the IP address:', error);
    });

  }catch (error) {
    console.error(error);
  }

}

export async function getUserByIP(){
  const ip = await getUserIP()
  try{
    const {data} = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getUser/${ip}`,
    );
    console.log("data is",data)
    return data.result[0].ID;

  }catch (error) {
    console.error(error);
  }

}



export async function createUser(){
  const ip = await getUserIP()
  try{
    const {data} = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/createUser`,
      { ip: ip }
    );
    return data.result;

  }catch (error) {
    console.error(error);
  }


}

// this adds select options to contractors dropdowns
export async function fetchContractors() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/contractorsName`
    );
    return response.data.result;
  } catch (error) {
    console.error(error);
  }
}

// this adds selections for existing Contacts
export async function fetchContacts() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getContacts`
    );

    // console.log(response.data.result);
  } catch (error) {
    console.error(error);
  }
}

/*  this is an event listener for input field with id actualFinishDate to change 
     the status to complete and check the checkbox input with id completion */

/*  this is an event listener for input field with id PlannedFinishDate to change 
     the status to complete and check the checkbox input with id completion */

/*  this is an event listener for input field with id PlannedFinishDate to change 
     the status to complete and check the checkbox input with id completion */

// this is an event listener for cwp dropdown to populate Planned Start and Planned Finish

//create a function to format date in yyyy-MM-dd format
export function formatDate2(date: any) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

// function to find the difference between two dates in days
export function dateDiffInDays(a: any, b: any) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
}

export const getcwafromCWP = (cwpOptions: any) => {
  const cwaOptions = [] as any;
  cwpOptions.forEach((cwp: any) => {
    const cwa = "CWA" + "-" + cwp.CWP.split("-")[1];
    if (!cwaOptions.includes(cwa)) {
      cwaOptions.push(cwa);
    }
  });
  return cwaOptions;
};
