/* eslint-disable no-restricted-globals */
// form for Student Personal Details using formik

import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import {
  IWPContactRegisterForm,
  IWPWithScope,
  TableColumn,
  IWPDailyLog,
} from "../../Types/IWPList";
import moment from "moment";
import { getcwafromCWP, getUserByIP } from "../../util/getIWPData";
import {
  Accordion,
  Modal as AddContactModal,
  Container,
  Nav,
  Navbar,
} from "react-bootstrap";
import { Modal as AddDelayModal } from "react-bootstrap";

import AddContactForm from "../IWPForm/AddContactForm";
import DailyLogsForm from "../IWPForm/AddDelayForm";
import Table from "../Table";
import Uploads from "../AddForm/Uploads";
import { useNavigate } from "react-router-dom";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import ComponentsTab from "./ComponentsTab";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { Application } from "@caxperts/universal.api/Application";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingSquare } from "react-loader-spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const IWPAddForm = ({
  cwpOptions,
  contractors,
  contactsChange,
  setContactsChange,
}: {
  cwpOptions: any;
  contractors: any;
  contactsChange: any;
  setContactsChange: any;
}) => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [message, setMessage] = useState("" as any);
  const [change, setChange] = useState(false);

  const [contacts, setContacts] = useState<IWPContactRegisterForm[]>();
  const [showContactModal, setShowContactModal] = useState(false);

  const [delays, setDelays] = useState<IWPDailyLog[]>([]);

  const [updatedUploads, setUpdatedUploads] = useState(false);
  const [uploads, setUploads] = useState([] as any);
  const [loading, setLoading] = useState(false);

  const [components, setComponents] = useState([] as any);
  const [disable, setDisable] = useState(false);
  const [user, setUser] = useState(0);
    useEffect(() => {
    const fetchUser = async() => {
      const userID = await getUserByIP()
      setUser(userID)
    }
    fetchUser()
  }, [])


  const Details = useRef<HTMLDivElement>(null);
  const Contact = useRef<HTMLDivElement>(null);
  const Schedule = useRef<HTMLDivElement>(null);
  const Scope = useRef<HTMLDivElement>(null);
  const Safety = useRef<HTMLDivElement>(null);
  const Quality = useRef<HTMLDivElement>(null);
  const UploadsRef = useRef<HTMLDivElement>(null);
  const ComponentsRef = useRef<HTMLDivElement>(null);

  const [activeKeys, setActiveKeys] = useState(["0"]);
  const handleSelect = (eventKey: AccordionEventKey) =>
    setActiveKeys(eventKey as string[]);
  const handleToggleClick = (indx: any) => {
    if (activeKeys.indexOf(indx) !== -1) {
      setActiveKeys([]);
    } else {
      setActiveKeys([indx]);
    }
  };

  const cwaOptions = getcwafromCWP(cwpOptions);

  const captureCoverPage = async () => {
    setDisable(true);
    let resultData = null;
    let scene = (await Application.getInstance().Scenes3d.get())[0];

    let base64 = await scene
      .takeScreenshot(1280, 720, 90, true, true, false, false)
      .returnAsBytes("png");
    let result = base64.ResultData.TakeScreenshot.ImageData;
    const chunkSize = Math.ceil(result.length / 20);
    for (let i = 0; i < 20; i++) {
      const chunk = result.slice(i * chunkSize, (i + 1) * chunkSize);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/uploadCaptures`,
        {
          chunk: chunk,
          chunkIndex: i,
          totalChunks: 15,
          iwp: formik.values.IWP,
          type: "CoverPage",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data && res.data.result) {
        resultData = res.data.result;
        setDisable(false);
        toast.info("Cover Page Screen shot added", {
          theme: "colored",
        });
      }
    }
    // console.log(resultData);
    setUploads([...uploads, resultData]);
  };

  const capture3DModelShots = async () => {
    setDisable(true);
    let resultData = null;
    let scene = (await Application.getInstance().Scenes3d.get())[0];

    let base64 = await scene
      .takeScreenshot(1280, 720, 90, true, true, false, false)
      .returnAsBytes("png");
    let result = base64.ResultData.TakeScreenshot.ImageData;
    const chunkSize = Math.ceil(result.length / 20);
    for (let i = 0; i < 20; i++) {
      const chunk = result.slice(i * chunkSize, (i + 1) * chunkSize);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/uploadCaptures`,
        {
          chunk: chunk,
          chunkIndex: i,
          totalChunks: 15,
          iwp: formik.values.IWP,
          type: "3DModelShots",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data && res.data.result) {
        resultData = res.data.result;
        setDisable(false);
        toast.info("3D Model Shots added", {
          theme: "colored",
        });
      }
    }
    setUploads([...uploads, resultData]);
  };

  const submitData = async (values: any) => {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/formData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...values,
        fileUploads: updatedUploads,
        userID: user
      }),
    });
    const data = await res.json();
    // console.log(data);
    if (!data.error) {
      addComponent();
      setLoading(false);

      setMessage(data);
      setTimeout(() => {
        setMessage("");
      }, 3000);

      axios.post(`${process.env.REACT_APP_BACKEND_URL}/add3DModelComponent`, {
        iwp: formik.values.IWP,
        data: {
          components: components,
        },
      });
    } else {
      setMessage(data);
    }
  };

  const addComponent = async () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/add3DModelComponent`, {
        iwp: formik.values.IWP,
        data: {
          components: components,
        },
      })
      .then((res) => {
        setLoading(false);
        toast.success("Your Iwp has been saved", {
          theme: "colored",
        });
        window.location.href = "/iwp";
      });
  };

  const addContact = async (contact: IWPContactRegisterForm) => {
    setShowContactModal(false);
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/addContact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ContactName: contact.Name,
        ContactEmail: contact.Email,
        ContactTitle: contact.Title,
        ContactNumber: contact.Number,
        IWP: formik.values.IWP,
      }),
    });
    const data = await res.json();
    // console.log(data);
    if (!data.error) {
      setChange(!change);
      setContactsChange(!contactsChange);
      setMessage(data);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  };

  const deleteContact = async (id: any) => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/deleteContactRegister`,
      {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          rowData: [id],
        }),
      }
    );
    const data = await res.json();
    // console.log(data);
    setChange(!change);
    setContactsChange(!contactsChange);
  };

  const formik = useFormik({
    initialValues: {
      EWP: "",
      CWP: "",
      CWA: "",
      IWP: "",
      Description: "",
      Status: "",
      Contractor: "",
      Discipline: "",
      constructionManager: "",
      safetyManager: "",
      materialManager: "",
      superintendent: "",
      generalForeman: "",
      foreman: "",
      workfacePlanner: "",
      PlannedHours: "",
      PlannedCrewSize: "",
      PlannedManpower: "",
      PlannedDurationDays: "",
      PlannedStart: "",
      PlannedFinish: "",
      ActualStart: "",
      ActualFinish: "",
      ActualCrewSize: "",
      ActualManpower: "",
      ActualDuration: "",
      CostCode: "",
      Type: "",
      Purpose: "",
      Contract: "",
      Safety: "",
      Quality: "",
      Scope: "",
      Notes: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      await submitData(values);

      formik.setSubmitting(false);
    },
    validationSchema: Yup.object({
      IWP: Yup.string().required("Required"),
      Status: Yup.string().required("Required"),
    }),
  });

  const handleBackClick = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to save changes before going back?",
      icon: "warning",
      color: "#f1f1f1",
      background: "#444444",
      showCancelButton: true,
      confirmButtonText: "Yes, save changes",
      cancelButtonText: "No, just go back",
    }).then((result) => {
      if (result.isConfirmed) {
        formik.handleSubmit();
      } else {
        navigate("/iwp");
      }
    });
  };

  useEffect(() => {
    const getContacts = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/getContactRegister?iwp=${formik.values.IWP}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();

      setContacts(data.result);
    };

    const getDailyLogs = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/getDailyLogs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ iwp: formik.values.IWP }),
        }
      );
      const data = await res.json();
      setDelays(data.result);
    };
    getContacts();
    getDailyLogs();
  }, [change, formik.values.IWP]);

  return (
    <>
      <Navbar bg="dark" data-bs-theme="dark" fixed="top">
        <button
          className="btn btn-secondary btn-lg ms-3 me-2 mb-1 p-1 px-3"
          onClick={() => {
            handleBackClick();
          }}
        >
          Back
        </button>
        <Container className="justify-content-center">
          <Nav>
            <Nav.Link
              className={`${
                activeKeys.includes("0") ? "active" : ""
              } me-lg-5 me-md-3 me-sm-0`}
              onClick={() => {
                handleToggleClick("0");
                Details.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                });
              }}
            >
              Details
            </Nav.Link>
            <Nav.Link
              className={`${
                activeKeys.includes("1") ? "active" : ""
              } me-lg-5 me-md-3 me-sm-0`}
              onClick={() => {
                Contact.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                });
                handleToggleClick("1");
              }}
            >
              Contacts
            </Nav.Link>
            <Nav.Link
              className={`${
                activeKeys.includes("2") ? "active" : ""
              } me-lg-5 me-md-3 me-sm-0`}
              onClick={() => {
                Schedule.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                });
                handleToggleClick("2");
              }}
            >
              Schedule
            </Nav.Link>
            <Nav.Link
              className={`${
                activeKeys.includes("3") ? "active" : ""
              } me-lg-5 me-md-3 me-sm-0`}
              onClick={() => {
                Scope.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                });
                handleToggleClick("3");
              }}
            >
              Scope
            </Nav.Link>
            <Nav.Link
              className={`${
                activeKeys.includes("4") ? "active" : ""
              } me-lg-5 me-md-3 me-sm-0`}
              onClick={() => {
                Safety.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                });
                handleToggleClick("4");
              }}
            >
              Safety
            </Nav.Link>
            <Nav.Link
              className={`${
                activeKeys.includes("5") ? "active" : ""
              } me-lg-5 me-md-3 me-sm-0`}
              onClick={() => {
                Quality.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                });
                handleToggleClick("5");
              }}
            >
              Quality
            </Nav.Link>
            <Nav.Link
              className={`${
                activeKeys.includes("6") ? "active" : ""
              } me-lg-5 me-md-3 me-sm-0`}
              onClick={() => {
                UploadsRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                });
                handleToggleClick("6");
              }}
            >
              Uploads
            </Nav.Link>
            <Nav.Link
              className={`${
                activeKeys.includes("7") ? "active" : ""
              } me-lg-5 me-md-3 me-sm-0`}
              onClick={() => {
                ComponentsRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                });
                handleToggleClick("7");
              }}
            >
              Components
            </Nav.Link>
          </Nav>
        </Container>
        <button
          className="btn btn-primary btn-lg  me-3 mb-1 p-1 px-3"
          type="submit"
          form="iwpForm"
          disabled={formik.isSubmitting}
        >
          Submit
        </button>
      </Navbar>
      {loading && (
        // <div className="d-flex align-items-center justify-content-center loader">
        //   <Spinner animation="border" variant="primary" />
        // </div>
        // <div className="d-flex align-items-center justify-content-center loader">
        //   <RotatingSquare
        //     height="150"
        //     width="150"
        //     color="#D3D3D3"
        //     ariaLabel="rotating-square-loading"
        //     strokeWidth="2"
        //     wrapperStyle={{}}
        //     wrapperClass=""
        //     visible={true}
        //   />
        // </div>
        // <div className="d-flex align-items-center justify-content-center loader">
        //   <img className="loadingImgInsight" src={InsightImage} alt="loading" />
        //   <img className="loadingImgGears" src={GearsIcon} alt="loading" />
        // </div>
        <div className="d-flex align-items-center justify-content-center loader">
          <RotatingSquare
            height="150"
            width="150"
            color="#D3D3D3"
            ariaLabel="rotating-square-loading"
            strokeWidth="2"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {!loading && (
        <>
          <ToastContainer autoClose={1500} />
          <div className="row g-md-3 g-lg-4 g-3 justify-content-end pt-3">
            <div className="col-12">
              <div className="d-flex flex-row justify-content-between align-items-center mt-5">
                <h1 className="m-0">Add IWP</h1>
              </div>
              <form id="iwpForm" onSubmit={formik.handleSubmit}>
                <Accordion
                  defaultActiveKey={["0"]}
                  alwaysOpen
                  data-bs-theme="dark"
                  activeKey={activeKeys}
                  onSelect={handleSelect}
                >
                  {/* IWP Details */}
                  <Accordion.Item eventKey="0" ref={Details}>
                    <Accordion.Header as={"h2"}>
                      IWP Details{" "}
                      {formik.touched.IWP && formik.errors.IWP ? (
                        <span className="invalid-feedback">
                          {formik.errors.IWP}
                        </span>
                      ) : null}
                      {formik.touched.Status && formik.errors.Status ? (
                        <span className="invalid-feedback">
                          {formik.errors.Status}
                        </span>
                      ) : null}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row ">
                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <input
                            type="text"
                            id="msf-pd-EWP"
                            className={`form-control ${
                              formik.touched.EWP && formik.errors.EWP
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="EWP"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.EWP}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            EWP
                          </label>
                          {formik.touched.EWP && formik.errors.EWP ? (
                            <div className="invalid-feedback">
                              {formik.errors.EWP}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <select
                            className={`form-select ${
                              formik.touched.CWP && formik.errors.CWP
                                ? "is-invalid"
                                : ""
                            }`}
                            name="CWP"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CWP}
                            aria-label="Default select example"
                          >
                            <option selected>Select an option</option>
                            {cwpOptions.map((cwp: any) => (
                              <option value={cwp.CWP}>{cwp.CWP}</option>
                            ))}
                          </select>
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            CWP
                          </label>
                          {formik.touched.CWP && formik.errors.CWP ? (
                            <div className="invalid-feedback">
                              {formik.errors.CWP}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <select
                            className={`form-select ${
                              formik.touched.CWA && formik.errors.CWA
                                ? "is-invalid"
                                : ""
                            }`}
                            name="CWA"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CWA}
                            aria-label="Default select example"
                          >
                            <option selected>Select an option</option>
                            {cwaOptions.map((cwa: any) => (
                              <option value={cwa}>{cwa}</option>
                            ))}
                          </select>
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            CWA
                          </label>
                          {formik.touched.CWA && formik.errors.CWA ? (
                            <div className="invalid-feedback">
                              {formik.errors.CWA}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <input
                            type="text"
                            id="msf-pd-IWP"
                            className={`form-control ${
                              formik.touched.IWP && formik.errors.IWP
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="IWP"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.IWP}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            IWP
                          </label>
                          {formik.touched.IWP && formik.errors.IWP ? (
                            <div className="invalid-feedback">
                              {formik.errors.IWP}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <input
                            type="text"
                            id="msf-pd-Description"
                            className={`form-control ${
                              formik.touched.Description &&
                              formik.errors.Description
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="Description"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Description}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Description
                          </label>
                          {formik.touched.Description &&
                          formik.errors.Description ? (
                            <div className="invalid-feedback">
                              {formik.errors.Description}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <select
                            className={`form-select ${
                              formik.touched.Status && formik.errors.Status
                                ? "is-invalid"
                                : ""
                            }`}
                            name="Status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Status}
                            aria-label="Default select example"
                          >
                            <option selected>Select an option</option>
                            <option value="Preliminary">Preliminary</option>
                            <option value="Issued for Construction">
                              Issued for Construction
                            </option>
                            <option value="Construction Started">
                              Construction Started
                            </option>
                            <option value="Backlog">Backlog</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Complete">Complete</option>
                          </select>
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Status
                          </label>
                          {formik.touched.Status && formik.errors.Status ? (
                            <div className="invalid-feedback">
                              {formik.errors.Status}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <input
                            type="text"
                            id="msf-pd-Contractor"
                            className={`form-control ${
                              formik.touched.Contractor &&
                              formik.errors.Contractor
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="Contractor"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Contractor}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Contractor
                          </label>
                          {formik.touched.Contractor &&
                          formik.errors.Contractor ? (
                            <div className="invalid-feedback">
                              {formik.errors.Contractor}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <select
                            className={`form-select ${
                              formik.touched.Discipline &&
                              formik.errors.Discipline
                                ? "is-invalid"
                                : ""
                            }`}
                            name="Discipline"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Discipline}
                            aria-label="Default select example"
                          >
                            <option selected>Select an option</option>
                            <option value="Piping">Piping</option>
                            <option value="Steel">Steel</option>
                            <option value="Civil">Civil</option>
                            <option value="Electrical">Electrical</option>
                            <option value="Instrumentation">
                              Instrumentation
                            </option>
                            <option value="Mechanical">Mechanical</option>
                            <option value="Insulation">Insulation</option>
                          </select>
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Discipline
                          </label>
                          {formik.touched.Discipline &&
                          formik.errors.Discipline ? (
                            <div className="invalid-feedback">
                              {formik.errors.Discipline}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <select
                            className={`form-select ${
                              formik.touched.constructionManager &&
                              formik.errors.constructionManager
                                ? "is-invalid"
                                : ""
                            }`}
                            name="constructionManager"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.constructionManager}
                            aria-label="Default select example"
                          >
                            <option selected>Select an option</option>
                            {contractors.map((contractor: any) => {
                              if (contractor.Title === "Construction Manager") {
                                return (
                                  <option value={contractor.Email}>
                                    {contractor.Name}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Construction Manager
                          </label>
                          {formik.touched.constructionManager &&
                          formik.errors.constructionManager ? (
                            <div className="invalid-feedback">
                              {formik.errors.constructionManager}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <select
                            className={`form-select ${
                              formik.touched.safetyManager &&
                              formik.errors.safetyManager
                                ? "is-invalid"
                                : ""
                            }`}
                            name="safetyManager"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.safetyManager}
                            aria-label="Default select example"
                          >
                            <option selected>Select an option</option>
                            {contractors.map((contractor: any) => {
                              if (contractor.Title === "Safety Manager") {
                                return (
                                  <option value={contractor.Email}>
                                    {contractor.Name}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Safety Manager
                          </label>
                          {formik.touched.safetyManager &&
                          formik.errors.safetyManager ? (
                            <div className="invalid-feedback">
                              {formik.errors.safetyManager}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <select
                            className={`form-select ${
                              formik.touched.materialManager &&
                              formik.errors.materialManager
                                ? "is-invalid"
                                : ""
                            }`}
                            name="materialManager"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.materialManager}
                            aria-label="Default select example"
                          >
                            <option selected>Select an option</option>
                            {contractors.map((contractor: any) => {
                              if (contractor.Title === "Material Manager") {
                                return (
                                  <option value={contractor.Email}>
                                    {contractor.Name}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Material Manager
                          </label>
                          {formik.touched.materialManager &&
                          formik.errors.materialManager ? (
                            <div className="invalid-feedback">
                              {formik.errors.materialManager}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <select
                            className={`form-select ${
                              formik.touched.superintendent &&
                              formik.errors.superintendent
                                ? "is-invalid"
                                : ""
                            }`}
                            name="superintendent"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.superintendent}
                            aria-label="Default select example"
                          >
                            <option selected>Select an option</option>
                            {contractors.map((contractor: any) => {
                              if (contractor.Title === "Superintendent") {
                                return (
                                  <option value={contractor.Email}>
                                    {contractor.Name}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Superintendent
                          </label>
                          {formik.touched.superintendent &&
                          formik.errors.superintendent ? (
                            <div className="invalid-feedback">
                              {formik.errors.superintendent}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <select
                            className={`form-select ${
                              formik.touched.generalForeman &&
                              formik.errors.generalForeman
                                ? "is-invalid"
                                : ""
                            }`}
                            name="generalForeman"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.generalForeman}
                            aria-label="Default select example"
                          >
                            <option selected>Select an option</option>
                            {contractors.map((contractor: any) => {
                              if (contractor.Title === "General Foreman") {
                                return (
                                  <option value={contractor.Email}>
                                    {contractor.Name}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            General Foreman
                          </label>
                          {formik.touched.generalForeman &&
                          formik.errors.generalForeman ? (
                            <div className="invalid-feedback">
                              {formik.errors.generalForeman}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <select
                            className={`form-select ${
                              formik.touched.foreman && formik.errors.foreman
                                ? "is-invalid"
                                : ""
                            }`}
                            name="foreman"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.foreman}
                            aria-label="Default select example"
                          >
                            <option selected>Select an option</option>
                            {contractors.map((contractor: any) => {
                              if (contractor.Title === "Foreman") {
                                return (
                                  <option value={contractor.Email}>
                                    {contractor.Name}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Foreman
                          </label>
                          {formik.touched.foreman && formik.errors.foreman ? (
                            <div className="invalid-feedback">
                              {formik.errors.foreman}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <select
                            className={`form-select ${
                              formik.touched.workfacePlanner &&
                              formik.errors.workfacePlanner
                                ? "is-invalid"
                                : ""
                            }`}
                            name="workfacePlanner"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.workfacePlanner}
                            aria-label="Default select example"
                          >
                            <option selected>Select an option</option>
                            {contractors.map((contractor: any) => {
                              if (contractor.Title === "Workface Planner") {
                                return (
                                  <option value={contractor.Email}>
                                    {contractor.Name}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Workface Planner
                          </label>
                          {formik.touched.workfacePlanner &&
                          formik.errors.workfacePlanner ? (
                            <div className="invalid-feedback">
                              {formik.errors.workfacePlanner}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Add Contact */}
                  <Accordion.Item eventKey="1" ref={Contact}>
                    <Accordion.Header as={"h2"}> IWP Contact</Accordion.Header>
                    <Accordion.Body>
                      <button
                        type="button"
                        className="btn btn-primary ms-auto"
                        onClick={() => setShowContactModal(true)}
                      >
                        Add Contact
                      </button>
                      {contacts && contacts.length > 0 && (
                        <Table
                          keyColumn="ID"
                          columns={ContactTableColumns}
                          tableData={contacts}
                          deleteColumnHandler={(id) => deleteContact(id)}
                        />
                      )}
                      <AddContactModal
                        data-bs-theme="dark"
                        show={showContactModal}
                        onHide={() => setShowContactModal(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <AddContactModal.Header closeButton>
                          <AddContactModal.Title>
                            Add Contact
                          </AddContactModal.Title>
                        </AddContactModal.Header>
                        <AddContactModal.Body>
                          <AddContactForm
                            handleChange={(contact: any) => {
                              addContact(contact);
                            }}
                          />
                        </AddContactModal.Body>
                      </AddContactModal>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* IWP Schedule */}
                  <Accordion.Item eventKey="2" ref={Schedule}>
                    <Accordion.Header as={"h2"}>IWP Schedule</Accordion.Header>
                    <Accordion.Body>
                      <div className="row ">
                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <DatePicker
                            id="msf-pd-PlannedStart"
                            render={<InputIcon />}
                            onChange={(date: any) => {
                              formik.setFieldValue("PlannedStart", date);
                            }}
                            className="bg-dark "
                            containerClassName="custom-container"
                            inputClass={`form-control spcl-inp ${
                              formik.touched.PlannedStart &&
                              formik.errors.PlannedStart
                                ? "is-invalid"
                                : ""
                            }`}
                            name="PlannedStart"
                            value={formik.values.PlannedStart}
                          />
                          <label
                            className="ms-2 spcl-label"
                            htmlFor="msf-pd-sin"
                          >
                            Planned Start
                          </label>
                          {formik.touched.PlannedStart &&
                          formik.errors.PlannedStart ? (
                            <div className="invalid-feedback">
                              {formik.errors.PlannedStart as any}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <DatePicker
                            id="msf-pd-PlannedFinish"
                            render={<InputIcon />}
                            onChange={(date: any) => {
                              formik.setFieldValue("PlannedFinish", date);
                            }}
                            className="bg-dark "
                            containerClassName="custom-container"
                            inputClass={`form-control spcl-inp ${
                              formik.touched.PlannedFinish &&
                              formik.errors.PlannedFinish
                                ? "is-invalid"
                                : ""
                            }`}
                            name="PlannedFinish"
                            value={formik.values.PlannedFinish}
                          />
                          <label
                            className="ms-2 spcl-label"
                            htmlFor="msf-pd-sin"
                          >
                            Planned Finish
                          </label>
                          {formik.touched.PlannedFinish &&
                          formik.errors.PlannedFinish ? (
                            <div className="invalid-feedback">
                              {formik.errors.PlannedFinish as any}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <input
                            type="number"
                            id="msf-pd-PlannedHours"
                            className={`form-control ${
                              formik.touched.PlannedHours &&
                              formik.errors.PlannedHours
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="PlannedHours"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.PlannedHours}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Planned Hours
                          </label>
                          {formik.touched.PlannedHours &&
                          formik.errors.PlannedHours ? (
                            <div className="invalid-feedback">
                              {formik.errors.PlannedHours}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <input
                            type="number"
                            id="msf-pd-PlannedCrewSize"
                            className={`form-control ${
                              formik.touched.PlannedCrewSize &&
                              formik.errors.PlannedCrewSize
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="PlannedCrewSize"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.PlannedCrewSize}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Planned Crew Size
                          </label>
                          {formik.touched.PlannedCrewSize &&
                          formik.errors.PlannedCrewSize ? (
                            <div className="invalid-feedback">
                              {formik.errors.PlannedCrewSize}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <input
                            type="text"
                            id="msf-pd-PlannedManpower"
                            className={`form-control ${
                              formik.touched.PlannedManpower &&
                              formik.errors.PlannedManpower
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="PlannedManpower"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.PlannedManpower}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Planned Manpower
                          </label>
                          {formik.touched.PlannedManpower &&
                          formik.errors.PlannedManpower ? (
                            <div className="invalid-feedback">
                              {formik.errors.PlannedManpower}
                            </div>
                          ) : null}
                        </div>

                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <input
                            type="number"
                            id="msf-pd-PlannedDurationDays"
                            className={`form-control ${
                              formik.touched.PlannedDurationDays &&
                              formik.errors.PlannedDurationDays
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="PlannedDurationDays"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.PlannedDurationDays}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Planned Duration (Days)
                          </label>
                          {formik.touched.PlannedDurationDays &&
                          formik.errors.PlannedDurationDays ? (
                            <div className="invalid-feedback">
                              {formik.errors.PlannedDurationDays}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-5">
                        {/* Actual start date  */}
                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <DatePicker
                            id="msf-pd-ActualStart"
                            render={<InputIcon />}
                            onChange={(date: any) => {
                              formik.setFieldValue("ActualStart", date);
                            }}
                            className="bg-dark "
                            containerClassName="custom-container"
                            inputClass={`form-control spcl-inp ${
                              formik.touched.ActualStart &&
                              formik.errors.ActualStart
                                ? "is-invalid"
                                : ""
                            }`}
                            name="ActualStart"
                            value={formik.values.ActualStart}
                          />
                          <label
                            className="ms-2 spcl-label"
                            htmlFor="msf-pd-sin"
                          >
                            Actual Start
                          </label>
                          {formik.touched.ActualStart &&
                          formik.errors.ActualStart ? (
                            <div className="invalid-feedback">
                              {formik.errors.ActualStart as any}
                            </div>
                          ) : null}
                        </div>

                        {/* Actual finish date  */}
                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <DatePicker
                            id="msf-pd-ActualFinish"
                            render={<InputIcon />}
                            onChange={(date: any) => {
                              formik.setFieldValue("ActualFinish", date);
                            }}
                            className="bg-dark "
                            containerClassName="custom-container"
                            inputClass={`form-control spcl-inp ${
                              formik.touched.ActualFinish &&
                              formik.errors.ActualFinish
                                ? "is-invalid"
                                : ""
                            }`}
                            name="ActualFinish"
                            value={formik.values.ActualFinish}
                          />
                          <label
                            className="ms-2 spcl-label"
                            htmlFor="msf-pd-sin"
                          >
                            Actual Finish
                          </label>
                          {formik.touched.ActualFinish &&
                          formik.errors.ActualFinish ? (
                            <div className="invalid-feedback">
                              {formik.errors.ActualFinish as any}
                            </div>
                          ) : null}
                        </div>

                        {/* Actual Crew Size  */}
                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <input
                            type="number"
                            id="msf-pd-ActualCrewSize"
                            className={`form-control ${
                              formik.touched.ActualCrewSize &&
                              formik.errors.ActualCrewSize
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="ActualCrewSize"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ActualCrewSize}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Actual Crew Size
                          </label>
                          {formik.touched.ActualCrewSize &&
                          formik.errors.ActualCrewSize ? (
                            <div className="invalid-feedback">
                              {formik.errors.ActualCrewSize}
                            </div>
                          ) : null}
                        </div>

                        {/* Actual Manpower  */}
                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <input
                            type="text"
                            id="msf-pd-ActualManpower"
                            className={`form-control ${
                              formik.touched.ActualManpower &&
                              formik.errors.ActualManpower
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="ActualManpower"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ActualManpower}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Actual Manpower
                          </label>
                          {formik.touched.ActualManpower &&
                          formik.errors.ActualManpower ? (
                            <div className="invalid-feedback">
                              {formik.errors.ActualManpower}
                            </div>
                          ) : null}
                        </div>

                        {/* Actual Duration  */}
                        <div className=" col-12 col-md-6 col-lg-4 form-floating mb-4">
                          <input
                            type="number"
                            id="msf-pd-ActualDuration"
                            className={`form-control ${
                              formik.touched.ActualDuration &&
                              formik.errors.ActualDuration
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="ActualDuration"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ActualDuration}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Actual Duration
                          </label>
                          {formik.touched.ActualDuration &&
                          formik.errors.ActualDuration ? (
                            <div className="invalid-feedback">
                              {formik.errors.ActualDuration}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* Scope Of Work */}
                  <Accordion.Item eventKey="3" ref={Scope}>
                    <Accordion.Header as={"h2"}>Scope of Work</Accordion.Header>
                    <Accordion.Body>
                      <div className="row  align-items-center">
                        {/* add scope as a text area input */}
                        <div className=" col-12 form-floating mb-4">
                          <textarea
                            rows={18}
                            className={`form-control ${
                              formik.touched.Scope && formik.errors.Scope
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="Scope"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Scope}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Scope
                          </label>
                          {formik.touched.Scope && formik.errors.Scope ? (
                            <div className="invalid-feedback">
                              {formik.errors.Scope}
                            </div>
                          ) : null}
                        </div>

                        {/* add notes as a text area input */}
                        <div className=" col-12 form-floating mb-4">
                          <textarea
                            rows={8}
                            className={`form-control ${
                              formik.touched.Notes && formik.errors.Notes
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="Notes"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Notes}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Notes
                          </label>
                          {formik.touched.Notes && formik.errors.Notes ? (
                            <div className="invalid-feedback">
                              {formik.errors.Notes}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* Safety */}
                  <Accordion.Item eventKey="4" ref={Safety}>
                    <Accordion.Header as={"h2"}>Safety</Accordion.Header>
                    <Accordion.Body>
                      <div className="row justify-content-center align-items-center">
                        {/* add safety as a text area input */}
                        <div className=" col-12 form-floating mb-4">
                          <textarea
                            rows={12}
                            className={`form-control ${
                              formik.touched.Safety && formik.errors.Safety
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="Safety"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Safety}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Safety
                          </label>
                          {formik.touched.Safety && formik.errors.Safety ? (
                            <div className="invalid-feedback">
                              {formik.errors.Safety}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Quality */}
                  <Accordion.Item eventKey="5" ref={Quality}>
                    <Accordion.Header as={"h2"}>Quality</Accordion.Header>
                    <Accordion.Body>
                      <div className="row justify-content-center align-items-center">
                        {/* add quality as a text area input */}
                        <div className=" col-12 form-floating mb-4">
                          <textarea
                            rows={12}
                            className={`form-control ${
                              formik.touched.Quality && formik.errors.Quality
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            name="Quality"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Quality}
                          />
                          <label className="ms-2" htmlFor="msf-pd-sin">
                            Quality
                          </label>
                          {formik.touched.Quality && formik.errors.Quality ? (
                            <div className="invalid-feedback">
                              {formik.errors.Quality}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Uploads */}
                  <Accordion.Item eventKey="6" ref={UploadsRef}>
                    <Accordion.Header as={"h2"}>Uploads</Accordion.Header>
                    <Accordion.Body>
                      {formik.values.IWP !== "" && (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary ms-auto me-4"
                            disabled={disable}
                            onClick={() => {
                              captureCoverPage();
                            }}
                          >
                            Capture Cover Page
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary ms-auto"
                            disabled={disable}
                            onClick={() => {
                              capture3DModelShots();
                            }}
                          >
                            Capture 3D Model Shots
                          </button>
                          <Uploads
                            iwp={formik.values.IWP}
                            uploads={uploads}
                            setUpdatedUploads={setUpdatedUploads}
                          />
                        </>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Approvals */}
                  {/* <div className="card  pt-4 text-bg-dark">
            <div className="card-body">
              <h5 className="card-title">Approvals</h5>
              
              <hr />
            </div>
          </div> */}

                  {/* Progress */}
                  {/* <div className="card  pt-4 text-bg-dark">
            <div className="card-body">
              <h5 className="card-title">Progress</h5>
              
              <hr />
            </div>
          </div> */}

                  <Accordion.Item eventKey="7" ref={ComponentsRef}>
                    <Accordion.Header as={"h2"}>Components</Accordion.Header>
                    <Accordion.Body>
                      {formik.values.IWP.length > 0 && (
                        <ComponentsTab
                          components={components}
                          setComponents={setComponents}
                        />
                      )}
                    </Accordion.Body>
                  </Accordion.Item>

                  <button
                    className="btn btn-dark btn-lg px-5 my-2 mt-5 ms-2"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </button>
                </Accordion>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const ContactTableColumns: TableColumn[] = [
  {
    name: "Name",
    label: "Contact",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Title",
    label: "Title",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Email",
    label: "Email",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Number",
    label: "Phone",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "delete",
    label: "Delete",
    options: {},
  },
];

const DelayTableColumns: TableColumn[] = [
  {
    name: "IWP",
    label: "IWP",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Date",
    label: "Date",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Crew_Size",
    label: "Crew Size",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Hours",
    label: "Hours",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Delay_Code",
    label: "Delay Code",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "ResponsibleParty",
    label: "Responsible Party",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Delay_Hours",
    label: "Delay Hours",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "RFI",
    label: "RFI",
    options: {},
  },
  {
    name: "RFIHours",
    label: "RFI Hours",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "edit",
    label: "Edit",
    options: {},
  },
  {
    name: "delete",
    label: "Delete",
    options: {},
  },
];
export default IWPAddForm;
