import { Application, CombineModes } from "@caxperts/universal.api";
import { useState } from "react";

const PowerApp = () => {
  const [input1, setInput1] = useState("00004e2e-0000-0000-3acf-75d8615a1e10");
  const [input2, setInput2] = useState("00004e2e-0000-0000-5ab6-75d8615a1e10");

  const handleInputChange = (e: any, setInput: any) => {
    setInput(e.target.value);
  };

  const handleSubmit = async () => {
    console.log("Input 1:", input1);
    console.log("Input 2:", input2);
    let scene = (await Application.getInstance().Scenes3d.get())[0];
    let filter = scene.getNewFilter();
    filter.IncludeAttributes = true;
    filter.CombineMode = CombineModes.Or;
    filter.Condition = "uid=" + input1 + "&uid=" + input2;
    filter.highlight();
    filter.select();
  };

  return (
    <div className="container mt-5">
      <h1>PowerApp</h1>
      <div className="mb-3">
        <label htmlFor="input1" className="form-label">
          Input 1:
        </label>
        <input
          type="text"
          className="form-control"
          id="input1"
          value={input1}
          onChange={(e) => setInput1(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="input2" className="form-label">
          Input 2:
        </label>
        <input
          type="text"
          className="form-control"
          id="input2"
          value={input2}
          onChange={(e) => setInput2(e.target.value)}
        />
      </div>
      <button className="btn btn-primary" onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
};

export default PowerApp;
