import DropZone from "./DropZone";

const FileUpload = ({ iwp, val, data, dispatch, dispatch2 }: any) => {
  return (
    <div className="main">
      {/* Pass state data and dispatch to the DropZone component */}
      <DropZone
        data={data}
        dispatch={dispatch}
        dispatch2={dispatch2}
        val={val}
        iwp={iwp}
      />
    </div>
  );
};
export default FileUpload;
