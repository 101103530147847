import { useFormik } from "formik";
import * as Yup from "yup";
import { IWPContactRegisterForm } from "../../Types/IWPList";

import CreatableSelect from "react-select/creatable";

const AddContactForm = ({ handleChange }: any) => {
  const formik = useFormik<IWPContactRegisterForm>({
    initialValues: {
      Name: "",
      Email: "",
      Title: "" as any,
      Number: "",
    },
    onSubmit: async (values: any) => {
      await handleChange(values);
      formik.setSubmitting(false);
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("First Name is required"),
      Title: Yup.string().required("Title is required"),
      Email: Yup.string()
        .required("Email is required")
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          "Email is not valid"
        ),
      Number: Yup.string()
        .required("Phone Number is required")
        .matches(
          /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
          "Phone Number must be 10 digits"
        ),
    }),
  });

  const formatPhoneNumber = (phoneNumber: any) => {
    // Remove any non-digit characters from the input
    const cleaned = phoneNumber.replace(/\D/g, "");

    // Format the cleaned number with dashes
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

    return formatted;
  };

  const handleTel = (e: any) => {
    const inputValue = e.target.value;
    const formattedInputValue = formatPhoneNumber(inputValue); // Implement your formatting logic here

    // Set the actual form field value
    formik.setFieldValue("Number", formattedInputValue);
  };

  // console.log(formik.values);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className=" col-12  col-md-6 col-lg-6  form-floating mb-4">
          <input
            type="text"
            id="msf-pd-firstName"
            className={`form-control ${
              formik.touched.Name && formik.errors.Name ? "is-invalid" : ""
            }`}
            placeholder="2018888"
            name="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Name}
          />
          <label className="ms-2" htmlFor="msf-pd-sin">
            Name
          </label>
          {formik.touched.Name && formik.errors.Name ? (
            <div className="invalid-feedback">{formik.errors.Name}</div>
          ) : null}
        </div>

        <div className=" col-12  col-md-6 col-lg-6  mb-4">
          {/* <input
            type="text"
            id="msf-pd-lastName"
            className={`form-control ${
              formik.touched.Title && formik.errors.Title ? "is-invalid" : ""
            }`}
            placeholder="2018888"
            name="Title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Title}
          /> */}
          <CreatableSelect
            id="msf-pd-lastName"
            className={`form-control p-0 ${
              formik.touched.Title && formik.errors.Title ? "is-invalid" : ""
            }`}
            name="Title"
            onChange={(newValue: any) => {
              // console.log(newValue);
              formik.setFieldValue("Title", newValue.value);
            }}
            onBlur={formik.handleBlur}
            placeholder={"Title"}
            value={
              formik.values.Title === ""
                ? ""
                : { value: formik.values.Title, label: formik.values.Title }
            }
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#444444",
                primary: "#e5e7eb",
              },
            })}
            options={CONTACT_OPTIONS}
          />
          {formik.touched.Title && formik.errors.Title ? (
            <div className="invalid-feedback">{formik.errors.Title}</div>
          ) : null}
        </div>

        <div className=" col-12  col-md-6 col-lg-6  form-floating mb-4">
          <input
            type="text"
            id="msf-pd-email"
            className={`form-control ${
              formik.touched.Email && formik.errors.Email ? "is-invalid" : ""
            }`}
            placeholder="2018888"
            name="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Email}
          />
          <label className="ms-2" htmlFor="msf-pd-sin">
            Email
          </label>
          {formik.touched.Email && formik.errors.Email ? (
            <div className="invalid-feedback">{formik.errors.Email}</div>
          ) : null}
        </div>

        <div className=" col-12  col-md-6 col-lg-6  form-floating mb-4">
          <input
            type="tel"
            maxLength={10}
            id="msf-pd-phone"
            className={`form-control ${
              formik.touched.Number && formik.errors.Number ? "is-invalid" : ""
            }`}
            placeholder="2018888"
            name="Number"
            onChange={(e) => {
              handleTel(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.Number}
          />
          <label className="ms-2" htmlFor="msf-pd-phone">
            Phone Number
          </label>
          {formik.touched.Number && formik.errors.Number ? (
            <div className="invalid-feedback">{formik.errors.Number}</div>
          ) : null}
        </div>

        <button
          className="btn btn-secondary btn-lg px-5 my-2 mt-5"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Add Contact
        </button>
      </div>
    </form>
  );
};

export default AddContactForm;

const CONTACT_OPTIONS: any = [
  { value: "Construction Manager", label: "Construction Manager" },
  { value: "Safety Manager", label: "Safety Manager" },
  { value: "Material Manager", label: "Material Manager" },
  { value: "Workface Planner", label: "Workface Planner" },
  { value: "Superintendent", label: "Superintendent" },
];
