import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchContractors, getCWP } from "../../util/getIWPData";
import IWPAddForm from "./IWPAddForm";

const IWPAdd = () => {
  const [cwpOptions, setCWPOptions] = useState();
  const [contractors, setContractors] = useState([]);
  const [contactsChange, setContactsChange] = useState(false);

  const { iwp } = useParams();

  useEffect(() => {
    const refreshContractors = async () => {
      fetchContractors().then((data) => {
        setContractors(data);
      });
    };
    refreshContractors();
  }, [contactsChange]);

  async function loadData() {
    Promise.all([getCWP(), fetchContractors()]).then(
      ([cwpData, contractorData]) => {
        setCWPOptions(cwpData);
        setContractors(contractorData as any);
      }
    );
  }

  useEffect(() => {
    loadData();
  }, [iwp]);

  return (
    <div>
      {contractors && cwpOptions && (
        <IWPAddForm
          cwpOptions={cwpOptions}
          contractors={contractors}
          contactsChange={contactsChange}
          setContactsChange={setContactsChange}
        />
      )}
    </div>
  );
};

export default IWPAdd;
