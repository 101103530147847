import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import "./styles/bootstrap.css";
import "semantic-ui-less/semantic.less";
import "devextreme/dist/css/dx.material.blue.dark.css";
import "devexpress-gantt/dist/dx-gantt.min.css";
import SelectedIWP from "./Components/IWP";
import IWPAdd from "./Components/AddForm/IWPAdd";
import Index from "./Components/Index";
import PowerBI1 from "./Components/PowerBI/PowerBI1";
import PowerBIPaginated from "./Components/PowerBI/PowerBIPaginated";
import TimelinePlayback from "./Components/4DPlayback";
import PowerApp from "./Components/PowerApp";
const queryClient = new QueryClient();

const IWPList = lazy(() => import("./Components/IWPList"));

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/powerbi" element={<PowerBI1 />} />
          <Route path="/powerbiPage" element={<PowerBIPaginated />} />
          <Route index path="/iwp" element={<IWPList />} />
          <Route path="/iwp/:iwp" element={<SelectedIWP />} />
          <Route path="/iwp/add" element={<IWPAdd />} />
          <Route path="/4D" element={<TimelinePlayback />} />
          <Route path="/powerapp" element={<PowerApp />} />
        </Routes>
      </Suspense>
    </Router>
  </QueryClientProvider>
);

export default App;
