import { useParams } from "react-router-dom";
import { IWP, IWPFormData, IWPWithScope } from "../Types/IWPList";
import axios from "axios";
import { useEffect, useState } from "react";
import IWPDetailsAndSchedule from "./IWPForm/IWPForm";
import { fetchContractors, getCWP } from "../util/getIWPData";
import { set } from "lodash";

const SelectedIWP = () => {
  const [formData, setFormData] = useState<IWPWithScope>();
  const [cwpOptions, setCWPOptions] = useState();
  const [contractors, setContractors] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [IWP, setIWP] = useState<IWP>();

  const [contactsChange, setContactsChange] = useState(false);

  const { iwp } = useParams();

  async function loadData() {
    Promise.all([fetchIWP(iwp as string), getCWP(), fetchContractors()]).then(
      ([iwpData, cwpData, contractorData]) => {
        console.log("data:",iwpData)
        setFormData({
          ...iwpData[0],
          notes: iwpData[0].notes || "",
          scope: iwpData[0].scope || "",
        });
        // select all the indexs of the iwpData array that have a documentType value and it does not contain a "/" in documentType and add them to the uploads array
        setUploads(
          iwpData.filter(
            (item: any) => item.documentType && !item.documentType.includes("/")
          )
        );
        setCWPOptions(cwpData);
        setContractors(contractorData as any);
      }
    );
  }

  useEffect(() => {
    loadData();
  }, [iwp]);

  useEffect(() => {
    const refreshContractors = async () => {
      fetchContractors().then((data) => {
        setContractors(data);
      });
    };
    refreshContractors();
  }, [contactsChange]);

  const fetchIWP = async (iwp: string) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/getIwpData`,
      { iwp: iwp }
    );
    return data.result;
  };

  return (
    <div>
      {formData && contractors && cwpOptions && (
        <IWPDetailsAndSchedule
          selIWP={formData}
          cwpOptions={cwpOptions}
          contractors={contractors}
          contactsChange={contactsChange}
          setContactsChange={setContactsChange}
          uploads={uploads}
          setUploads={setUploads}
        />
      )}
    </div>
  );
};

export default SelectedIWP;
