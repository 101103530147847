import { useFormik } from "formik";
import * as Yup from "yup";
import { IWPDailyLog } from "../../Types/IWPList";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";

const DailyLogsForm = ({ handleChange, initialDelayData }: any) => {
  const formik = useFormik<IWPDailyLog>({
    initialValues: {
      ID: initialDelayData.ID !== undefined ? initialDelayData.ID : -1,
      Date:
        initialDelayData.Date !== undefined
          ? initialDelayData.Date
          : new Date(),
      Crew_Size:
        initialDelayData.Crew_Size !== undefined
          ? initialDelayData.Crew_Size
          : 0,
      Hours: initialDelayData.Hours !== undefined ? initialDelayData.Hours : 0,
      Ashton_Cost_Code:
        initialDelayData.Ashton_Cost_Code !== undefined
          ? initialDelayData.Ashton_Cost_Code
          : "",
      Delay_Code:
        initialDelayData.Delay_Code !== undefined
          ? initialDelayData.Delay_Code
          : "",
      Delay_Hours:
        initialDelayData.Delay_Hours !== undefined
          ? initialDelayData.Delay_Hours
          : 0,
      RFI: initialDelayData.RFI !== undefined ? initialDelayData.RFI : "",
      RFIHours:
        initialDelayData.RFIHours !== undefined ? initialDelayData.RFIHours : 0,
      ResponsibleParty:
        initialDelayData.ResponsibleParty !== undefined
          ? initialDelayData.ResponsibleParty
          : "",
    },
    onSubmit: async (values) => {
      await handleChange(values);
      formik.setSubmitting(false);
    },
    validationSchema: Yup.object({
      Date: Yup.string().required("Date is required"),
      Delay_Hours: Yup.number().required("Delay Hours is required"),
      // Email: Yup.string()
      //   .required("Email is required")
      //   .matches(
      //     /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      //     "Email is not valid"
      //   ),
      // Number: Yup.string()
      //   .required("Phone Number is required")
      //   .matches(/^[0-9]{10}$/, "Phone Number must be 10 digits"),
    }),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className=" col-12  col-md-6 col-lg-4  form-floating mb-4">
          <DatePicker
            id="msf-pd-Date"
            render={<InputIcon />}
            onChange={(date: any) => {
              formik.setFieldValue("Date", date);
            }}
            className="bg-dark "
            containerClassName="custom-container"
            inputClass={`form-control spcl-inp ${
              formik.touched.Date && formik.errors.Date ? "is-invalid" : ""
            }`}
            name="Date"
            value={formik.values.Date}
          />
          <label className="ms-2 spcl-label" htmlFor="msf-pd-sin">
            Date
          </label>
          {formik.touched.Date && formik.errors.Date ? (
            <div className="invalid-feedback">Date is required</div>
          ) : null}
        </div>

        <div className=" col-12  col-md-6 col-lg-4  form-floating mb-4">
          <input
            type="number"
            id="msf-pd-Crew_Size"
            className={`form-control ${
              formik.touched.Crew_Size && formik.errors.Crew_Size
                ? "is-invalid"
                : ""
            }`}
            placeholder="2018888"
            name="Crew_Size"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Crew_Size}
          />
          <label className="ms-2" htmlFor="msf-pd-sin">
            Crew Size
          </label>
          {formik.touched.Crew_Size && formik.errors.Crew_Size ? (
            <div className="invalid-feedback">{formik.errors.Crew_Size}</div>
          ) : null}
        </div>

        <div className=" col-12  col-md-6 col-lg-4  form-floating mb-4">
          <input
            type="number"
            id="msf-pd-Hours"
            className={`form-control ${
              formik.touched.Hours && formik.errors.Hours ? "is-invalid" : ""
            }`}
            placeholder="2018888"
            name="Hours"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Hours}
          />
          <label className="ms-2" htmlFor="msf-pd-sin">
            Hours
          </label>
          {formik.touched.Hours && formik.errors.Hours ? (
            <div className="invalid-feedback">{formik.errors.Hours}</div>
          ) : null}
        </div>

        <div className=" col-12  col-md-6 col-lg-4  form-floating mb-4">
          <input
            type="text"
            id="msf-pd-Ashton_Cost_Code"
            className={`form-control ${
              formik.touched.Ashton_Cost_Code && formik.errors.Ashton_Cost_Code
                ? "is-invalid"
                : ""
            }`}
            placeholder="2018888"
            name="Ashton_Cost_Code"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Ashton_Cost_Code}
          />
          <label className="ms-2" htmlFor="msf-pd-sin">
            Cost Code
          </label>
          {formik.touched.Ashton_Cost_Code && formik.errors.Ashton_Cost_Code ? (
            <div className="invalid-feedback">
              {formik.errors.Ashton_Cost_Code}
            </div>
          ) : null}
        </div>

        <div className=" col-12  col-md-6 col-lg-4  form-floating mb-4">
          <input
            type="text"
            id="msf-pd-Delay_Code"
            className={`form-control ${
              formik.touched.Delay_Code && formik.errors.Delay_Code
                ? "is-invalid"
                : ""
            }`}
            placeholder="2018888"
            name="Delay_Code"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Delay_Code}
          />
          <label className="ms-2" htmlFor="msf-pd-sin">
            Delay Code
          </label>
          {formik.touched.Delay_Code && formik.errors.Delay_Code ? (
            <div className="invalid-feedback">{formik.errors.Delay_Code}</div>
          ) : null}
        </div>

        <div className=" col-12  col-md-6 col-lg-4  form-floating mb-4">
          <input
            type="number"
            id="msf-pd-Delay_Hours"
            className={`form-control ${
              formik.touched.Delay_Hours && formik.errors.Delay_Hours
                ? "is-invalid"
                : ""
            }`}
            placeholder="2018888"
            name="Delay_Hours"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Delay_Hours}
          />
          <label className="ms-2" htmlFor="msf-pd-sin">
            Delay Hours
          </label>
          {formik.touched.Delay_Hours && formik.errors.Delay_Hours ? (
            <div className="invalid-feedback">{formik.errors.Delay_Hours}</div>
          ) : null}
        </div>

        <div className=" col-12  col-md-6 col-lg-4  form-floating mb-4">
          <input
            type="text"
            id="msf-pd-RFI"
            className={`form-control ${
              formik.touched.RFI && formik.errors.RFI ? "is-invalid" : ""
            }`}
            placeholder="2018888"
            name="RFI"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.RFI}
          />
          <label className="ms-2" htmlFor="msf-pd-sin">
            RFI
          </label>
          {formik.touched.RFI && formik.errors.RFI ? (
            <div className="invalid-feedback">{formik.errors.RFI}</div>
          ) : null}
        </div>

        <div className=" col-12  col-md-6 col-lg-4  form-floating mb-4">
          <input
            type="number"
            id="msf-pd-RFIHours"
            className={`form-control ${
              formik.touched.RFIHours && formik.errors.RFIHours
                ? "is-invalid"
                : ""
            }`}
            placeholder="2018888"
            name="RFIHours"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.RFIHours}
          />
          <label className="ms-2" htmlFor="msf-pd-sin">
            RFIHours
          </label>
          {formik.touched.RFIHours && formik.errors.RFIHours ? (
            <div className="invalid-feedback">{formik.errors.RFIHours}</div>
          ) : null}
        </div>

        <div className=" col-12  col-md-6 col-lg-4  form-floating mb-4">
          <input
            type="text"
            id="msf-pd-ResponsibleParty"
            className={`form-control ${
              formik.touched.ResponsibleParty && formik.errors.ResponsibleParty
                ? "is-invalid"
                : ""
            }`}
            placeholder="2018888"
            name="ResponsibleParty"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.ResponsibleParty}
          />
          <label className="ms-2" htmlFor="msf-pd-sin">
            Responsible Party
          </label>
          {formik.touched.ResponsibleParty && formik.errors.ResponsibleParty ? (
            <div className="invalid-feedback">
              {formik.errors.ResponsibleParty}
            </div>
          ) : null}
        </div>

        <button
          className={`btn btn-lg px-5 my-2 mt-5 ${
            Object.keys(initialDelayData).length > 0
              ? "btn-secondary"
              : "btn-secondary"
          }`}
          type="submit"
          disabled={formik.isSubmitting}
        >
          {Object.keys(initialDelayData).length > 0 ? "Edit " : "Add "}
        </button>
      </div>
    </form>
  );
};

export default DailyLogsForm;
