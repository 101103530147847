import { useEffect, useState } from "react";
import Table from "../Table";
import { TableColumn } from "../../Types/IWPList";
import axios from "axios";
import { set } from "lodash";
import { Dna } from "react-loader-spinner";
import { Application } from "@caxperts/universal.api/Application";
import { CombineModes } from "@caxperts/universal.api/Util/Enums";

const ComponentsTab = ({ components, setComponents }: any) => {
  const [loading, setLoading] = useState(false);

  const addComponent = async () => {
    let scene = (await Application.getInstance().Scenes3d.get())[0];
    let filter = scene.getNewFilter();
    filter.IncludeAttributes = true;
    // filter.condition = "Name=D-240";
    filter.CombineMode = CombineModes.Or;
    const selectedObjects = await filter.getSelectedObjects();
    const resultArray = [] as any;
    // // console.log(selectedObjects);

    selectedObjects.forEach((obj) => {
      const UID = obj.getAttribute("Uid");
      let name = obj.getAttribute("PieceIdentifier");
      const task = obj.getAttribute("Task");

      // Conditionally set 'name' based on 'task'
      // if (task === "Structure") {
      //   name = obj.getAttribute("Name");
      // } else if (task === "Piping") {
      //   name = obj.getAttribute("PipeRun");
      // }

      // Construct a JSON object and push it to the result array
      const jsonObject = { UID: UID, type: task, name: name, progress: 0 };
      resultArray.push(jsonObject);
      // const newComponent = [] as any;

      let resultRefined = [] as any;
      resultArray.forEach((resultItem: any) => {
        // find only that are unique from resultarray and not in components
        resultRefined = resultArray.filter(
          (resultItem: any) =>
            !components.find(
              (component: any) => component.UID === resultItem.UID
            )
        );
      });

      setComponents([...components, ...resultRefined]);
    });
  };

  const deleteComponent = (UID: string) => {
    const newComponents = components.filter(
      (component: any) => component.UID !== UID
    );
    setComponents(newComponents);
  };

  const handleChange = (key: string, name: string, value: string) => {
    const newComponents = components.map((component: any) => {
      if (component.UID === key) {
        return { ...component, [name]: value };
      } else {
        return component;
      }
    });
    setComponents(newComponents);
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary ms-auto"
        onClick={() => {
          addComponent();
        }}
      >
        Add Component
      </button>
      {loading && (
        <Dna
          visible={true}
          height="30"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      )}
      {components.length > 0 && (
        <Table
          columns={ComponentTableColumns}
          triggerChange={(keyId, triggerColumn, triggerValue) => {
            handleChange(keyId, triggerColumn, triggerValue);
          }}
          keyColumn="UID"
          tableData={components}
          deleteColumnHandler={deleteComponent}
        />
      )}
    </div>
  );
};

export default ComponentsTab;

const ComponentTableColumns: TableColumn[] = [
  {
    name: "UID",
    label: "Component ID",
    options: {
      hide: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Component Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "type",
    label: "Type",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "progress",
    type: "input-number",
    label: "Progress",
    options: {},
  },
  {
    name: "delete",
    label: "Delete",
    options: {
      filter: false,
      sort: false,
    },
  },
];
