/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-new-wrappers */
/* eslint-disable no-self-compare */
import axios from "axios";
import React from "react";
// import styles from "../../styles/filePreview.module.css";

const FilePreview = ({ data, dispatch, name, iwp }: any) => {
  const deleteFile = async (action: any) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/deleteUploads`,
      { documentName: action.originalName, iwp: action.iwp }
    );
    // console.log(data);
    if (data.message === "Success") {
      // console.log("file deleted");
      if (action.name !== "saved") {
        dispatch({
          type: "REMOVE_FILE_FROM_LIST",
          name: action.originalName,
          iwp: action.iwp,
          originalName: action.originalName,
          id: action.id,
        });
      } else {
        dispatch({
          type: "REMOVE_DB_FILE_FROM_LIST",
          name: action.originalName,
          iwp: action.iwp,
          originalName: action.originalName,
          id: action.id,
        });
      }
    }
  };
  let files = data.fileList.filter((f: any) => f.name === name)[0].files;
  files = files.filter((file: any) => {
    return !file.documentType.endsWith("/PNG");
  });
  return (
    <div className="fileList">
      <div className="fileContainer">
        {/* loop over the fileData */}
        {files.map((file: any) => {
          return (
            <ol key={file.url}>
              <li className="fileList">
                {/* display the filename and type */}
                <div
                  key={file.originalname || file.originalName}
                  className="fileName badge rounded-pill text-bg-info p-2"
                >
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <a target="_blank" href={file.url || file.blobUrl}>
                      {file.originalname || file.originalName}
                    </a>
                    <p
                      className="text-danger ms-2 "
                      style={{
                        cursor: "pointer",
                        fontSize: "1.2rem",
                      }}
                      onClick={
                        // dispatch action to remove file from fileList
                        () =>
                          deleteFile({
                            type: "REMOVE_FILE_FROM_LIST",
                            name: file.originalname || "saved",
                            originalName:
                              file.originalName || file.originalname,
                            iwp: iwp,
                            id: name,
                          })
                      }
                    >
                      {" "}
                      x
                    </p>
                  </div>
                </div>
              </li>
            </ol>
          );
        })}
      </div>
    </div>
  );
};

export default FilePreview;
