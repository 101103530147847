import _ from "lodash";
import React, { useEffect } from "react";
import { Table } from "semantic-ui-react";
import { TableColumn } from "../../../Types/IWPList";
import UploadTableRow from "./UploadTableRow";
import axios from "axios";

function exampleReducer(state: any, action: any): any {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: "ascending",
      };
    default:
      throw new Error();
  }
}

const uploadReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_IN_DROP_ZONE":
      return { ...state, inDropZone: action.inDropZone };
    case "ADD_FILE_TO_LIST":
      // add the action.files to the files of the object that has mane equal to action.id in the fileList
      return {
        ...state,
        fileList: state.fileList.map((f: any) => {
          if (f.name === action.id) {
            return { ...f, files: [...f.files, ...action.files] };
          }
          return f;
        }),
      };
    case "REMOVE_FILE_FROM_LIST":
      return {
        ...state,
        fileList: state.fileList.map((f: any) => {
          if (f.name === action.id) {
            return {
              ...f,
              files: f.files.filter(
                (file: any) => file.originalname !== action.name
              ),
            };
          }
          return f;
        }),
      };
    case "REMOVE_DB_FILE_FROM_LIST":
      return {
        ...state,
        fileList: state.fileList.map((f: any) => {
          if (f.name === action.id) {
            return {
              ...f,
              files: f.files.filter(
                (file: any) => file.originalName !== action.originalName
              ),
            };
          }
          return f;
        }),
      };
    case "REFRESH_FILELIST":
      return {
        ...state,
        fileList: state.fileList.map((f: any) => {
          return {
            ...f,
            files: action.files.filter((file: any) => {
              if (file.documentType === f.name) {
                return true;
              }
              return false;
            }),
          };
        }),
      };
    default:
      throw new Error();
  }
};

const UploadTable = ({ columns, iwp, uploads, setUpdatedUploads }: any) => {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    column: null,
    data: null,
    direction: null,
  });

  const [uploadData, uploadDispatch] = React.useReducer(uploadReducer, {
    inDropZone: false,
    fileList: [
      {
        name: "CoverPage",
        files: [],
      },
      {
        name: "3DModelShots",
        files: [],
      },
      {
        name: "Pictures",
        files: [],
      },
      {
        name: "EngineeringDocuments",
        files: [],
      },
      {
        name: "Quality",
        files: [],
      },
      {
        name: "SiteMap",
        files: [],
      },
    ],
  });

  // combile all the files in the fileList into one array
  const allFiles = uploadData.fileList.reduce((acc: any, curr: any) => {
    return [...acc, ...curr.files];
  }, []);

  // check if the files in the fileList are in the uploads
  useEffect(() => {
    setUpdatedUploads(allFiles);
  }, [uploadData.fileList]);

  useEffect(() => {
    // console.log(uploadData);
    uploadDispatch({
      type: "REFRESH_FILELIST",
      files: uploads,
    });
  }, [uploads]);

  // console.log("uploadDataAdd");

  const { column, data, direction } = state;
  return (
    <Table sortable celled inverted selectable>
      <Table.Header>
        <Table.Row>
          {columns.map((tcolumn: TableColumn) => (
            <Table.HeaderCell
              key={tcolumn.name}
              sorted={column === tcolumn.name ? direction : null}
              onClick={() =>
                tcolumn.options.sort &&
                dispatch({ type: "CHANGE_SORT", column: tcolumn.name })
              }
            >
              {tcolumn.label}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {rowValues.map((rowValue) => (
          <UploadTableRow
            key={rowValue.name}
            val={rowValue}
            iwp={iwp}
            data={uploadData}
            dispatch={uploadDispatch}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const rowValues = [
  {
    name: "CoverPage",
    title: "Cover Page",
  },
  {
    name: "3DModelShots",
    title: "3D Model Shots",
  },
  {
    name: "Pictures",
    title: "Pictures",
  },
  {
    name: "EngineeringDocuments",
    title: "Engineering Documents",
  },
  {
    name: "Quality",
    title: "Quality",
  },
  {
    name: "SiteMap",
    title: "Site Map",
  },
];

export default UploadTable;
