import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import FileUpload from "./FileUpload";
import FilePreview from "./FilePreview";
import { Dna } from "react-loader-spinner";
import InsightImage from "../../../public/Insight-AWP-The-Future-is-Smart.svg";
import GearsIcon from "../../../public/gears-loading.gif";

const UploadTableRow = ({ val, iwp, data, dispatch }: any) => {
  const [loading, setLoading] = useState(false);
  const uploadReducer2 = (state: any, action: any) => {
    switch (action.type) {
      case "FILES_NOT_UPLOADED":
        setLoading(true);
        return { uploaded: action.uploaded, imageType: action.imageType };
      case "FILES_UPLOADED":
        setLoading(false);
        return { uploaded: action.uploaded, imageType: null };
      default:
        throw new Error();
    }
  };
  const [uploadFilesData, uploadFilesDataDispatch] = React.useReducer(
    uploadReducer2,
    {
      uploaded: false,
      imageType: null,
    }
  );

  return (
    <Table.Row className="table-row" key={val}>
      <Table.Cell className="col-3">{val.title}</Table.Cell>
      <Table.Cell className="col-3">
        {loading && val.name === uploadFilesData.imageType ? (
          <>
            <div className="d-flex align-items-center justify-content-center">
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </div>
            {/* <div className="d-flex align-items-center justify-content-center uploadLoad">
              <img
                className="uploadLoadingImgInsight"
                src={InsightImage}
                alt="loading"
              />
              <img
                className="uploadLoadingImgGears"
                src={GearsIcon}
                alt="loading"
              />
            </div> */}
            <div className="d-flex align-items-center justify-content-center">
              <p>Please Wait while documents are being uploaded.</p>
            </div>
          </> // Return null to hide the row
        ) : (
          // Pass the unique key to each FileUpload component
          <FileUpload
            val={val.name}
            data={data} // Data for this specific FileUpload component
            dispatch={dispatch}
            dispatch2={uploadFilesDataDispatch}
            iwp={iwp}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {/* Pass the unique key to each FilePreview component */}
        <FilePreview
          data={data} // Data for this specific FileUpload component
          dispatch={dispatch}
          name={val.name}
          iwp={iwp}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default UploadTableRow;
