import { useEffect, useState } from "react";
import UploadTable from "../AddForm/Upload/UploadTable";
import { TableColumn } from "../../Types/IWPList";

const Uploads = ({ iwp, uploads, setUpdatedUploads }: any) => {
  const columns: TableColumn[] = [
    {
      name: "File Type",
      label: "File Type",
      options: {},
    },
    {
      name: "Upload",
      label: "Upload",
      options: {},
    },
    {
      name: "File Names",
      label: "File Names",
      options: {},
    },
  ];

  return (
    <div>
      <UploadTable
        columns={columns}
        iwp={iwp}
        uploads={uploads}
        setUpdatedUploads={setUpdatedUploads}
      />
    </div>
  );
};

export default Uploads;
